<template>
  <div>
    <div class="compont_p">我的电子发票</div>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column prop="no" label="订单编号" align="center" />
      <el-table-column prop="taitou" label="发票抬头" align="center" />
      <!-- <el-table-column prop="invoice_no" label="发票号码" align="center" /> -->
      <el-table-column prop="addtime" label="开票日期" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="check flex_c_c c_p" @click="handleClick(scope.row)">下载</div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    this.getEinvoice();
  },
  methods: {
    getEinvoice() {
      this.$api("account.getEinvoice", {
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getEinvoice();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getEinvoice();
    },
    handleClick(row) {
      if (!row.pdf_url) return;
      window.open(row.pdf_url);
    },
  },
};
</script>

<style lang="less" scoped>
.el-icon-shopping-cart-2 {
  font-size: 21px;
  color: @themeColor;
  margin-right: 15px;
  cursor: pointer;
}
.el-icon-delete {
  font-size: 20px;
  color: #ff4c4c;
  cursor: pointer;
}
</style>
